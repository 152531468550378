/* eslint-disable max-classes-per-file */
import * as Sentry from '@sentry/browser';

// Utilities
import { featureFlagEnabled } from 'common/utils/helpers';

// log errors
export const logError = (error, context = {}) => {
  Object.entries(context).forEach((entries) => {
    const [key, value] = entries;
    Sentry.setContext(key, value);
  });
  Sentry.captureException(error);
  if (featureFlagEnabled('sentry-feedback-modal')) {
    Sentry.showReportDialog();
  }
};

export class InvalidGeoJsonError extends Error {
  constructor() {
    super('Error: Not a valid GeoJSON');
  }
}
export class UnsupportedFeaturesError extends Error {
  /**
   * @param {string[]} features - The list of supported features.
   */
  constructor(features) {
    super(`Warning: All features except ${features} will be ignored`);
  }
}

export class NoSupportedFeaturesError extends Error {
  /**
   * @param {string[]} features - The list of supported features.
   */
  constructor(features) {
    super(
      `There are no supported features in your GeoJSON. Only supported features are ${features}`
    );
  }
}
