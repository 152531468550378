// TODO: This component needs to be heavily refactored, splitting up the
// root component, the routing, state connection, dom parsing logic, etc

// Libraries
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-polyfill';
import 'raf/polyfill';
import 'proxy-polyfill/proxy.min';
import amplitude from 'amplitude-js';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/browser';
import { ExtraErrorData, HttpClient } from '@sentry/integrations';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';

// State
import store from 'state/store';

// Context
import ModalProvider from 'contexts/Modal/ModalProvider';

// i18n
import i18n from 'i18n';

// Utilities
import {
  jsonaDeserialize,
  populatePath,
  featureFlagEnabled,
  rtlLanguage,
} from 'common/utils/helpers';
import { logAmplitudeEvent } from 'common/utils/analytics';
import { setupEmbeddedContent } from 'common/utils/embed';
import { useGoogleTracking } from 'common/hooks/useGoogleTracking';

// Routes
import Routes from 'routes';

// Components
import ScrollToTop from 'common/components/ScrollToTop';
import NotFoundPageContainer from 'common/components/NotFoundPageContainer';
import IdeaBoardContainer from 'ideation/components/ideaBoard/IdeaBoardContainer';
import IdeaContainer from 'ideation/components/idea/IdeaContainer';
import EngagementContainer from 'engagement/components/EngagementContainer';
import AnnouncementContainer from 'announcement/components/AnnouncementContainer';
import ProjectContainer from 'project/components/ProjectContainer';
import ProjectsContainer from 'project/components/ProjectsContainer';
import CategoryContainer from 'project/components/CategoryContainer';
import DiscussionTopicContainer from 'discussionTopic/components/DiscussionTopicContainer';
import Sitemap from 'sitemap/components/Sitemap';

const envTest = /^v[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
let viteEnv = 'production';

if (!envTest.test(import.meta.env.VITE_CIVIL_SPACE_VERSION)) {
  viteEnv = 'staging-uat';
}

if (import.meta.env.DEV) {
  viteEnv = 'development';
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `rails-${import.meta.env.VITE_CIVIL_SPACE_VERSION}`,
  environment: viteEnv,
  integrations: [new ExtraErrorData(), new HttpClient(), new Sentry.BrowserTracing()],
  sendDefaultPii: true,
  tracesSampleRate: 0.05,
});

amplitude.getInstance().init(window.gon?.amplitudeApiKey, undefined, {
  batchEvents: true,
  eventUploadPeriodMillis: 100,
});
window.amplitudeInstance = amplitude.getInstance();

// Log events triggered from rails
if (window.gon?.analyticsEvents) window.gon.analyticsEvents.forEach(logAmplitudeEvent);

const el = document.querySelector('#react-app');
let deserializedData = {};
let data = {};

const titleCase = (str) =>
  str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join('');

const buildSortObject = (item) => ({
  id: Number(item.id),
  type: titleCase(item.type),
});

// Sort all associated records together
const sortAssociatedRecords = (sortOrder, records) => {
  if (!sortOrder) return records;
  return records.sort((first, second) => {
    const firstObject = buildSortObject(first);
    const secondObject = buildSortObject(second);
    return (
      sortOrder.findIndex((item) => item.id === firstObject.id && item.type === firstObject.type) -
      sortOrder.findIndex((item) => item.id === secondObject.id && item.type === secondObject.type)
    );
  });
};

if (el.getAttribute('data')) {
  data = JSON.parse(el.getAttribute('data'));

  if (data) {
    switch (data.type) {
      case 'home': // For home page
        deserializedData = {
          projects: jsonaDeserialize(data.projects),
          categories: jsonaDeserialize(data.categories),
          opportunities: jsonaDeserialize(data.opportunities),
        };
        break;
      case 'categoryHome': // For category home page
        deserializedData = {
          category: jsonaDeserialize(data.category),
          projects: jsonaDeserialize(data.projects),
          opportunities: jsonaDeserialize(data.opportunities),
        };
        break;
      case 'sitemap': // For sitemap'
        deserializedData = {
          projects: jsonaDeserialize(data.projects),
          categories: jsonaDeserialize(data.categories),
        };
        break;
      default:
        deserializedData = jsonaDeserialize(data);

        if (deserializedData.type === 'project' || deserializedData.type === 'projects') {
          const sortArray = [...deserializedData.publicEngagements];
          if (deserializedData.publicIdeaBoards) {
            sortArray.push(...deserializedData.publicIdeaBoards);
          }
          if (deserializedData.publicDiscussionTopics) {
            sortArray.push(...deserializedData.publicDiscussionTopics);
          }

          deserializedData.sortedAssociatedRecords = sortAssociatedRecords(
            data.data?.meta?.associations_sort_order,
            sortArray
          );
        }
    }
  }
}

if (gon.theme) {
  document.documentElement.classList.add(`theme-${gon.theme}`);
}

if (rtlLanguage()) {
  document.documentElement.classList.add('rtl');
}

setupEmbeddedContent();

const App = () => {
  useGoogleTracking(window.googleTrackingIds);

  return (
    <div className="app">
      <Switch>
        <Redirect
          strict
          exact
          from={Routes.ROOT}
          to={populatePath(Routes.LOCALE, { locale: window.gon.currentLocale })}
        />
        <Route
          strict
          path={Routes.ENGAGEMENT}
          render={(props) => (
            <EngagementContainer
              {...props}
              engagement={data}
              preview={Boolean(data?.meta?.preview)}
            />
          )}
        />
        {featureFlagEnabled('premium_tier') && (
          <Route strict path={Routes.IDEA_BOARD} component={IdeaBoardContainer} />
        )}
        {featureFlagEnabled('premium_tier') && (
          <Route
            strict
            path={Routes.IDEA}
            render={(props) => <IdeaContainer {...props} idea={deserializedData} />}
          />
        )}
        <Route
          strict
          path={Routes.ANNOUNCEMENT}
          render={(props) => <AnnouncementContainer {...props} announcement={deserializedData} />}
        />
        <Route
          strict
          path={Routes.DISCUSSION_TOPIC}
          render={(props) => (
            <DiscussionTopicContainer {...props} discussionTopic={deserializedData} />
          )}
        />
        <Redirect exact strict push from={Routes.PROJECT_PREVIEW} to={Routes.PROJECT} />
        <Route
          strict
          path={Routes.PROJECT}
          render={(props) => <ProjectContainer {...props} project={deserializedData} />}
        />
        <Route
          strict
          exact
          path={Routes.CATEGORY}
          render={(props) => <CategoryContainer {...props} {...deserializedData} />}
        />
        <Route
          strict
          exact
          path={Routes.CATEGORY_ALIAS}
          render={(props) => <CategoryContainer {...props} {...deserializedData} />}
        />
        <Route
          strict
          exact
          path={Routes.SITEMAP}
          render={(props) => <Sitemap {...props} {...deserializedData} />}
        />
        <Route
          strict
          exact
          path={Routes.LOCALE}
          render={(props) => <ProjectsContainer {...props} {...deserializedData} />}
        />
        <Route component={NotFoundPageContainer} />
      </Switch>
      <Toaster />
    </div>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <CookiesProvider>
        <ModalProvider>
          <BrowserRouter>
            <ScrollToTop>
              <Suspense fallback={null}>
                <App />
              </Suspense>
            </ScrollToTop>
          </BrowserRouter>
        </ModalProvider>
      </CookiesProvider>
    </I18nextProvider>
  </Provider>,
  el
);
