const ROOT = '/';
const LOCALE = `${ROOT}:locale`;
const PROJECT = `${LOCALE}/projects/:projectId`;
const PROJECT_PREVIEW = `${PROJECT}/preview`;
const IDEA_BOARD = `${PROJECT}/idea_boards/:ideaBoardId`;
const IDEA = `${LOCALE}/idea_boards/:ideaBoardId/ideas/:ideaId`;
const DISCUSSION_TOPIC = `${PROJECT}/discussion_topics/:discussionTopicId`;
const PASSWORD_RESET = `${LOCALE}/reset_password`;
const ENGAGEMENT = `${PROJECT}/engagements/:engagementId`;
const ENGAGEMENT_PREVIEW = `${ENGAGEMENT}/preview`;
const ENGAGEMENT_SECTION = `${ENGAGEMENT}/sections/:position`;
const ENGAGEMENT_QUIT = `${ENGAGEMENT}/quit`;
const ENGAGEMENT_CLOSED = `${ENGAGEMENT}/closed`;
const ENGAGEMENT_ERROR = `${ENGAGEMENT}/error`;
const ENGAGEMENT_REPORT = `${PROJECT}/engagement_reports/:engagementReportId`;
const ANNOUNCEMENT = `${PROJECT}/announcements/:announcementId`;
const CATEGORY = `${LOCALE}/categories/:categoryId`;
const CATEGORY_ALIAS = `${LOCALE}/c/:categoryId`;
const ADMIN_LOGIN = `${ROOT}admin`;
const SITEMAP = `${LOCALE}/sitemap`;

export default Object.freeze({
  ROOT,
  PROJECT,
  PROJECT_PREVIEW,
  LOCALE,
  IDEA_BOARD,
  IDEA,
  DISCUSSION_TOPIC,
  PASSWORD_RESET,
  ENGAGEMENT,
  ENGAGEMENT_PREVIEW,
  ENGAGEMENT_SECTION,
  ENGAGEMENT_QUIT,
  ENGAGEMENT_CLOSED,
  ENGAGEMENT_ERROR,
  ENGAGEMENT_REPORT,
  ANNOUNCEMENT,
  CATEGORY,
  CATEGORY_ALIAS,
  ADMIN_LOGIN,
  SITEMAP,
});
