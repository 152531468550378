import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import Header from 'header/components/Header';
import { populatePath } from 'common/utils/helpers';
import routes from 'routes';

// Define the Sitemap component
const Sitemap = (props) => {
  const { projects, categories, match } = props;
  const getProjectUrl = (projectId) => populatePath(routes.PROJECT, { ...match.params, projectId });
  const getCategoryUrl = (categoryId) =>
    populatePath(routes.CATEGORY_ALIAS, { ...match.params, categoryId });

  const { t } = useTranslation();

  return (
    <>
      <Header />
      <main className="sitemap-main">
        <h1>
          <a href="/">{t('common.home')}</a>
        </h1>

        {categories.length > 0 && (
          <>
            <h2>{t('common.categories')}</h2>
            <ul>
              {categories.map((category) => (
                <li key={category.id}>
                  <a href={`${getCategoryUrl(category.slug)}`}>{category.title}</a>
                </li>
              ))}
            </ul>
          </>
        )}

        {projects.length > 0 && (
          <>
            <h2>{t('common.projects')}</h2>
            <ul>
              {projects.map((project) => (
                <li key={project.id}>
                  <a href={`${getProjectUrl(project.slug)}`}>{project.title}</a>
                </li>
              ))}
            </ul>
          </>
        )}
      </main>
    </>
  );
};

Sitemap.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

// Export the Sitemap component
export default withRouter(withTranslation()(Sitemap));
