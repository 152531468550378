// TODO: Refactor existing modals to use this API and move to common

// Libraries
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

// Contexts
import ModalContext from 'contexts/Modal/ModalContext';

// Components
import BaseModal from 'ideation/components/base/BaseModal';

// TODO: Remove 'skipFocusTrap' prop after bulk options modal refactor
const Modal = ({ isOpen, onClose, children, skipFocusTrap, className, header }) => {
  const { setActiveModal } = useContext(ModalContext);
  useEffect(() => {
    setActiveModal(
      isOpen ? (
        <BaseModal
          onClose={onClose}
          skipFocusTrap={skipFocusTrap}
          className={className}
          header={header}
        >
          {children}
        </BaseModal>
      ) : null
    );
  }, [isOpen]);
  return null;
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  skipFocusTrap: PropTypes.bool,
  header: PropTypes.node,
};

Modal.defaultProps = {
  skipFocusTrap: false,
  className: null,
  header: null,
  onClose: () => {},
};

export default Modal;
